<template>
	<div>
		<div class="container-dashboard-view">
			<div class="img-logo-dashboard-view">
				<img class="img-logo-dashboard-view" src="../assets/img//logoemsilvia.png" alt="logoEmSilvia">
			</div>
			<h1 class="title-dashboard-view">Empresa Municipal de Acueducto, Alcantarillado y Aseo de Silvia</h1>
		</div>
	</div>
</template>

<script>

export default ({
	components: {
	},
	data() {
		return {
		}
	},
})

</script>

<style lang="scss">
.container-dashboard-view {
	background-color: white;
	padding-top: 100px;
	padding-bottom: 100px;
	width: 100%;
	height: 85vh;
	display: flex;
	justify-content: center;
	align-items: center;
	border-radius: 10px;
	flex-direction: column;
	border-color: transparent;
	border-width: 0px;
}

.img-logo-dashboard-view {
	margin: auto;
	padding: 0px;
	width: 50%;
	object-fit: cover;
	background-repeat: no-repeat;	
}

.title-dashboard-view {
	padding: 0px;	
	width: 60%;
	text-align: center;
}
</style>